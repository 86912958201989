<template>
  <section id="patients">
    <b-overlay
      :show="isLoading"
      rounded="sm">
    <!-- Users Table Card -->
    <b-card no-body>
      <!-- Table Top -->
      <b-row>
        <b-col cols="12" md="12">
          <div class="d-flex align-items-center justify-content-end mt-2 mr-2">
            <b-button variant="primary" @click="showSidebar=true; data.selectedUser={}">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span class="text-nowrap">Add user</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <users-table
        @editUser="editUser"
				@deleteUser="deleteUser"

        :table-data="data.users"
        class="mt-2 mt-md-2"
        style="min-height: 400px;"
        />
        
        
    </b-card>
    <!--/ Users Table Card -->

    <!-- SIDEBAR -->
    <b-sidebar
      width="40%"
      right
      shadow
      parent="body"
      default-index="1"
      spacer
      v-model="showSidebar"
      title="User settings"
      class="sidebar"
    >
      <div class="px-3 py-2">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="First name"
                label-for="inputFirstname"
                label-cols-md="12"
              >
                <b-form-input id="inputFirstname" placeholder="First name" v-model="data.selectedUser.firstname" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Surname"
                label-for="inputSurname"
                label-cols-md="12"
              >
                <b-form-input id="inputSurname" placeholder="Surname" v-model="data.selectedUser.lastname" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="E-mail address"
                label-for="inputEmail"
                label-cols-md="12"
                
                >
                <b-form-input id="inputEmail" placeholder="E-mail address" v-model="data.selectedUser.email" :disabled="data.selectedUser.id>0"/>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Role"
                label-for="inputRole"
                label-cols-md="12"
              >
                <v-select
                  id="inputRole"
                  v-model="data.selectedUser.Role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  value="id"
                  :options="data.roles"
                  placeholder="Select role"
                />
                
              </b-form-group>
            </b-col>
            <b-col md="12" v-if="data.selectedUser.id">
              <b-form-group
                label="Status"
                label-for="inputStatus"
                label-cols-md="12"
              >
                <b-form-checkbox
                  id="inputStatus"
                  v-model="data.selectedUser.active"
                  name="check-button"
                  switch
                  inline
                >Access / no access
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- save -->
            <b-col md="12" class="mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="saveUser"
              >
                <template v-if="data.selectedUser.id">Save</template>
                <template v-else>Invite</template>
              </b-button>
              <b-button v-if="data.selectedUser.activated == 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="outline-secondary"
                class="mr-1 btn-outline-secondary"
                @click="resendInvitation(data.selectedUser)"
              >
                <template>Resend invitation</template>
              </b-button>              
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-sidebar>
    <!-- /SIDEBAR -->
  </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BForm,
  BOverlay,
  BFormGroup,
  BFormInput,
  BButton,
  BSidebar,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import UsersTable from "./UsersTable.vue";

import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      isLoading:false,
      showSidebar: false,
      optionsRole: [{ title: "Admin" }, { title: "Patients" }],
      selectedRole: [],
      data: {
        selectedUser:{},
        roles:[],
        users: [
          // {
          //   id: 1,
          //   firstname: "Jim",
          //   surname: "Peterson",
          //   email: "jim@nhs.co.uk",
          //   role: "admin",
          //   status: "active",
          //   status_variant: "success",
          //   postcode: "GU16 7HF",
          //   dob: "1981-04-16",
          //   gender: "M",
          // },
          // {
          //   id: 2,
          //   firstname: "Sandra",
          //   surname: "Williams",
          //   email: "sandra@nhs.co.uk",
          //   role: "algorithm",
          //   status: "invited",
          //   status_variant: "warning",
          // },
          // {
          //   id: 3,
          //   firstname: "Clarke",
          //   surname: "Johnson",
          //   email: "clarke@nhs.co.uk",
          //   role: "patients",
          //   status: "inactive",
          //   status_variant: "secondary",
          // },
        ],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,
    vSelect,
    Cleave,
    UsersTable,
    BSidebar,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  methods: {
    editUser(user) {
      console.log('editUser', user)
			this.data.selectedUser = { ...user };
			this.showSidebar = true;
			
		},
		async saveUser() {
			if (!this.data.selectedUser.id && !confirm("Are you sure you want to invite this new user?")) return;
      this.isLoading = true;
      var method = 'post';
      var url = '/users';
      var activeState = 1
      if (this.data.selectedUser.id) {
        activeState = this.data.selectedUser.active
        method = 'put';
        url = '/users/'+this.data.selectedUser.id
      }
			const res = await this.callApi(method, url, {...this.data.selectedUser, rol_id:this.data.selectedUser.Role.id, active:activeState});
      this.data.selectedUser = {};
      this.showSidebar = false;
			this.loadUsers();
		},
		async resendInvitation(user) {
			if (!confirm("Are you sure you want to resend an invitation to this user?")) return;
			const res = await this.callApi("post", "/users/resendInvitation/" + user.id);

      if (res.data.result) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'New invitation sent',
            icon: 'EditIcon',
            variant: 'success',
          },
        });
        this.data.selectedUser = {};
        this.showSidebar = false;
      }
			//this.loadUsers();
		},
		async deleteUser(user) {
			//if (!confirm("Are you sure you want to delete this user?")) return;
			const res = await this.callApi("delete", "/users/" + user.id);
			this.loadUsers();
		},
    async loadRoles() {
      const res = await this.callApi('get', '/roles');
        this.data.roles = res.data.result;
    },
    async loadUsers() {
      this.isLoading = true;
      const res = await this.callApi('get', '/users');
        this.data.users = res.data.result;
        this.isLoading = false;
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.dobSelected = ctx.selectedYMD;
    },
  },
  mounted() {
    this.loadRoles();
    this.loadUsers();
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.col-form-label {
  min-height: 2.45rem;
}

.vs__dropdown-toggle {
  background: #fff;
}
</style>