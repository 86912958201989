<template>
    <b-table
      v-if="tableData"
      hover
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- id --> 
      <template #cell(id)="data">
        <div class="font-weight-bolder">
          {{ data.item.id }}
        </div>
      </template>
      
      <!-- name -->
      <template #cell(fullname)="data">
         <a href="#" @click="editUser(data.item)">{{ data.item.firstname }} {{ data.item.lastname }}</a>
      </template>

      <!-- email -->
      <template #cell(email)="data">
        {{ data.item.email }}
      </template>

      <!-- role -->
      <template #cell(Role)="data">
        {{ data.item.Role.name }}
      </template>

      <!-- status -->
      <template #cell(active)="data">
        <div class="batch_container">
          <span v-if="data.item.active && data.item.activated">
            <b-badge
              
              variant="success"
              
              title="User has access"
            >
              ACCESS
            </b-badge>
          </span>          
          <span v-if="data.item.active && !data.item.activated">
            <b-badge
              
              variant="warning"
              
              title="User has access (awaiting user activation)"
            >
              ACCESS
            </b-badge>
          </span>
          <span v-if="!data.item.active">
            <b-badge
              
              variant="danger"
              
              title="User has no access"
            >
              NO ACCESS
            </b-badge>
          </span>
        </div>
      </template>


      <!-- status -->
      <template #cell(pidActive)="data">
        <div class="batch_container">
          <span v-if="data.item.pidActive">
            <b-badge
              
              variant="success"
              
              title="Active API user"
            >
              ACTIVE
            </b-badge>
          </span>
          <span v-if="!data.item.pidActive">
            <b-badge
              
              variant="danger"
              
              title="Inactive API user"
            >
              INACTIVE
            </b-badge>
          </span>
        </div>
      </template>

      <!-- actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            @click="editUser(data.item)"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Edit"
            class="cursor-pointer"
            target="test"
          />
        
          <feather-icon
            @click="confirmDeleteUser(data.item)"
            icon="TrashIcon"
            style="margin-left:1em;"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Delete"
            class="cursor-pointer"
            target="test"
          />

        <!-- <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="data.item.active" @click="setInactive(data.item)">
            <feather-icon icon="PauseCircleIcon" />
            <span class="align-middle ml-50">Set inactive</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="!data.item.active" @click="setActive(data.item)">
            <feather-icon icon="PlayCircleIcon" />
            <span class="align-middle ml-50">Set active</span>
          </b-dropdown-item>
        </b-dropdown> -->

        </div>

      </template>

    </b-table>
</template>

<script>
import {
  BCard, 
  BTable, 
  BAvatar, 
  BImg,
  BDropdown,
  BDropdownItem,  
  BTooltip,
  BBadge,
} from 'bootstrap-vue'

export default {
  emits:['editUser', 'deleteUser'],
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BBadge,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'fullname', label: 'NAME' },
        { key: 'email', label: 'E-MAIL ADDRESS' },
        { key: 'Role', label: 'ROLE' },
        { key: 'active', label: 'STATUS' },
        { key: 'pidActive', label: 'PID API' },
        { key: "actions", label: "ACTIONS" },
      ],
    }
  },
  methods: {
    confirmDeleteUser(user) {
      if (confirm("Are you sure you want to delete user "+user.fullname+"?")) {
        this.deleteUser(user)
      }
    },
    editUser(user) {
      this.$emit('editUser',user)
    },
    deleteUser(user) {
      this.$emit('deleteUser',user)
    },    
    setInactive(item) {
      console.log('setInactive', item)
    },
    setActive(item) {
      console.log('setActive', item)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
